.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    // height: 100%;
}
  
.modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    // height: 100vh;
    // width: 100vw;
}
  
.modal.open {
    opacity: 1;
    visibility: visible;
}
  
.modal__content {
    background-color: #fcfcfc;
    padding: 10px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 10000;
    width: 90%;
    max-width: 600px;
    max-height: 100%;
    overflow-y: hidden;

    .modal-head {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;

        .head-text {
            font-size: 1.2em;
            font-weight: bold;
        }
    }

    &.narrow {
        max-width: 400px;
    }

    &.wide {
        max-width: 750px;
    }

    .close-button-div {
		text-align: right;
		
		.close-button {
			cursor: pointer;
		}
	}

    .children-div {
        max-height: 80vh;
        // overflow-y: scroll;
        padding: 0 ;
    }
}

