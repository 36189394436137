.modal-content {

    .modal-search-box {
        padding: 10px 0;
    }

    h4 {
        padding: 0 20px;
    }

    
}