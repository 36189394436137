.head-bar-container {
    // margin-left: 300px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    

    .user-box {
        position: relative;
        
        .user-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .user-icon {
                cursor: pointer;
            }

            .token-display {
                display: flex;
                align-items: center;
                margin-right: 14px;
                cursor: pointer;

                img {
                    width: 30px;
                    margin-right: 7px;
                }
                span {
                    font-weight: bold;
                    font-size: 1.1em;
                }
            }


        }
    }

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 100px;
        // width: 100%;
        cursor: pointer;
    
        .g-card {
            padding: 7px 14px;
            flex: .8;
    
            
        }

        .logo {
            // width: 100%;
            max-width: 200px;
            // height: auto;
            // margin-right: 10px;
        }
        
        .logo-text {
            font-size: 24px;
            font-weight: bold;
        
        }
    }
    
}

