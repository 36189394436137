.user-icon-div {
    display: flex;
    justify-content: center;
    align-items: center; 

    &.reverse {
        flex-direction: row-reverse;
    }

    .user-icon {
        border-radius: 50%;
        width: 35px;
        margin: 0 10px;
    }
}