.watchlist-container {

    .watchlist-sidebar {

        .watchlist-sidebar-item {
            max-height: 80px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            margin-bottom: 10px;
            box-shadow: 0 1px 5px rgba(0,0,0,.2);
            transition: all .2s ease-in-out;
            cursor: pointer;

            .watchlist-sidebar-image {
                max-height: 70px;
                border-radius: 5px 0 0 5px;
            }

            .watchlist-sidebar-service {
                max-width: 60px;
                border-radius: 3px;
                margin-top: 3px;
            }
            

            .watchlist-sidebar-text {
                font-size: .8em;
                width: 100%;
                padding: 0 10px;
            }

            &:hover {
                box-shadow: 0px 5px 10px rgba(0,0,0,.2);
                transform: translateY(-1px);
            }
        }
    }
}