.sidebar-right-container {
    width: 100%;
    padding: 20px;
    width: 320px;
    // max-height: 50%;
    height: 100%;

    // .g-card {
    //     padding: 12px;
    //     // margin: 20px;
    // }
}