.desktop-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 80px);

    .content-container {
        // margin-left: 300px;
        padding: 20px;
        flex: 1;
    }
    
    .sidebar {
        // position: fixed;
        height: 100%;
        // border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.mobile-content-container {
    margin-top: 80px;
}