.search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-left: 16px;

    form {
        flex:1 ;

        .search-input {
            padding: 8px 23px;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
            border-radius: 23px;
            border: none;
            font-family: 'Lexend', 'sans serif';
            // font-size: 16px;
            // flex: .8;
            width: 95%;
            background-color: var(--bg-color);

            &:focus {
                outline: none;
            }

            &.mobile {
                width: 100%;
            }
            
        }
    }

    .search-icon {
        // width: 5px;
        // overflow: visible;
        flex: 0;
        transform: translateX(-4.5vw);
        cursor: pointer;

        &.mobile {
            transform: translateX(-11vw);
        }
    }
}