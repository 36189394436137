.dropdown-container {
    height: 0px;
    width: 160px;
    background-color: #fff;
    border-radius: .2em;
    z-index: 999;
    transition: height .3s ease-in-out;
    overflow: hidden;
    margin-top: 10px;
    position: absolute;
    // top: 70px;
    right: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

    &.active {
        height: 114px;
    }

    .dropdown-menu {
        padding: 10px;

        & > a {
            text-decoration: none;
            color: var(--gamably-color);
            display: block;
            line-height: 30px;
            font-size: 12px;
        }
    
    }
}