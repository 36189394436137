.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}
  
.mobile-header__logo img {
    height: 36px;
}
  
.mobile-header__menu {
    display: flex;
    align-items: center;
}

.mobile-header__menu-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.mobile-header__menu-button {
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    // margin-left: 10px;
}
  
.mobile-header__menu-items {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background-color: #fff;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transform: translateX(-110%);
    transition: transform 0.3s ease-in-out;
    z-index: 99;
}
  
.mobile-header__menu-items a {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.menu-logo {
    width: 50px;
}
  
.mobile-header__menu-items a:hover {
    color: #007aff;
}
  
.mobile-header__menu-items.show {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
}

.close-button {
    text-align: right;
}