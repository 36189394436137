.search-results {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 75vh;
    // justify-content: space-evenly;
    
    .modal-loader {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .result-card {
        // padding: 20px;
        border-radius: 10px;
        display: flex;
        // width: calc(100% - 150px);
        width: 100%;
        height: 225px;
        margin: 10px 0;
        box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
        // background-color: #f7f7f7;

        img {
            // float: left;
            width: 150px;
            // height: 225px;
            // object-fit: cover;
            border-radius: 10px 0 0 10px;
        }
        
        .result-text {
            padding: 10px;

            .card-title {
                margin-top: 0;
            }
            .card-description {
                margin-bottom: 20px;
                font-size: 10px;
            }

            .card-buttons {
                display: flex;

                .card-button {
                    margin-right: 10px;
                    padding: 10px 20px;
                    border-radius: 5px;
                    background-color: var(--prim);
                    color: #fff;
                    border: none;
                    cursor: pointer;
                }
                  
                .card-button:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    
    .mobile-result-card {
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        // height: 225px;
        margin: 10px 0;
        width: 100%;
        box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
        padding: 20px;

        img {
            // float: left;
            width: 160px;
            // height: 225px;
            // object-fit: cover;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        .result-text{
            text-align: center;

            .card-buttons {
                display: flex;
                flex-wrap: wrap;

                .card-button {
                    margin-right: 10px;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: var(--prim);
                    color: #fff;
                    border: none;
                    cursor: pointer;
                }
                  
                .card-button:last-child {
                    margin-right: 0;
                }
            }
        }
    }


}