.profile-image {
	width: 150px;
}

.image-div {
	text-align:center;
}

input[type=file] {
    display: none;
}