.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .landing-icon {
        width: 30%;
        min-width: 250px;
    }
}