.sidebar-right {
    display: grid;
    // justify-content: stretch;
    // flex-direction: column;
    grid-template-rows: 1fr 1fr;
    height: 100%;
    // align-content: stretch;

    .g-card {
        padding: 12px;
        position: relative;

        &.top {
            margin-bottom: 10px;
            // height: 50%;
            // overflow:hidden;
        }

        &.bottom {
            margin-top: 10px;
            // height: 50%;
            // overflow:hidden;
        }

        .sidebar-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                cursor: pointer;
            }
        }
    }

    p {
        margin: 0;
    }

    hr {
        margin: 10px 0; 
    }

    .scroll-box {
        position: absolute;
        overflow-x: auto;
        padding: 5px 10px 5px 0px;
        height: 75%;
        width: 92%;
    }
}