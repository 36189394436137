.profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 40px 0;

    .profile-logo {
        max-width: 400px;
        margin-bottom: 30px;
    }

    .g-card {
        width: 100%;
        max-width: 600px;
    }

    .buttons {
        text-align: right;
        
        .save-profile-button {
            width: 190px;
        }
    }

    #word-count {
        text-align: right;
        font-size: 12px;
    }
}