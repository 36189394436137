

.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    
    // box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);

    &.mobile {
        max-width: 100%;
    }
}
  

  

  
.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 90%;
    margin: auto;
}
  
.upload-button {
    padding: 10px 20px;
    background-color: #DC3F57;
    width: 130px;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.15);
}
  
.upload-button:hover {
    background-color: #2980b9;
}
  
.menu-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 8px;
}
  
.menu-item {
    display: flex;
    align-items: center;
    // font-size: 16px;
    margin-bottom: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-left: 4px solid transparent;
    padding: 5px 10px;
    width: 100%;
    border-radius: 0 10px 10px 0;

    &.active,
    &:hover {
        background: linear-gradient(90deg, var(--prim-trans) 50%, var(--prim-trans2) 100%);
        border-left: 4px solid var(--prim);
    }

    
}

.menu-item-icon {
    width: 30px;
    margin-right: 20px;
}


@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
    transition: $property $duration $ease;
}

$bg: #f3f8fa;
$white: #fff;
$black: #282936;

.start-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    width: 12rem;
    height: auto;

    .circle {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: relative;
        display: block;
        margin: 0;
        width: 3rem;
        height: 3rem;
        background: var(--prim);
        background: linear-gradient(180deg, var(--prim) 0%, var(--prim-dark) 100%);
        border-radius: 1.625rem;

        .icon {
            @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background: $white;

            &.arrow {
                @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
                left: 0.625rem;
                width: 1.125rem;
                height: 0.125rem;
                background: none;
                
                &::before {
                    position: absolute;
                    content: '';
                    top: -0.25rem;
                    right: 0.0625rem;
                    width: 0.625rem;
                    height: 0.625rem;
                    border-top: 0.125rem solid #fff;
                    border-right: 0.125rem solid #fff;
                    transform: rotate(45deg);
                }
            }
        }
    }
    .button-text {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0.75rem 0;
        margin: 0 0 0 1.85rem;
        color: #666;
        font-weight: 700;
        line-height: 1.6;
        text-align: center;
        // text-transform: uppercase;
    }

    &.clicked,
    &:hover {
        
        .circle {
            width: 100%;
            
            .icon {
            
                &.arrow {
                    background: $white;
                    transform: translate(1rem, 0);
                }
            }
        }

        .button-text {
            color: $white;
        }
    }
}

.drop-container {

    height: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;

    &.active {
        height: 180px;
        padding: 15px 4px;
    }

    .drop-menu {
        padding: 10px;
        // margin: 15px;
        border-radius: 24px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
        // height: 100%;
        width: 80%;

        .drop-menu-item {
            padding: 4px 10px;
            cursor: pointer;
            border-radius: 15px;
            transition: background-color .2s ease-in-out;
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .drop-menu-item-icon {
                width: 20px;
            }

            &.active,
            &:hover {
                background-color:  var(--prim-trans);
                // background-image: linear-gradient(90deg, var(--prim-trans) 50%, var(--prim-trans2) 100%);

            }
            
        }
    }
}