.services-modal {

    p {
        margin: 20px;
    }

    .services-container {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        

        .service-option {
            width: 48%;
            border-radius: 5px;
            box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.3);
            margin: 5px 0;
            transition: all .3s ease-in-out;

            &:hover {
                box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.3);
                transform: scale(1.1);
            }

            &.active {
                box-shadow: 0px 0px 15px #F95C4A;
                
                img {
                    filter: grayscale(1);
                    opacity: 0.2;
                }
                // transform: scale(1.1);
            }

            img {
                width: 100%;
                border-radius: 5px;
                transition: all .3s ease-in-out;
            }
        }
    }
}