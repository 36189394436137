@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&family=Open+Sans+Condensed:wght@300&display=swap');

:root {
    --prim: #F95C4A;
    --prim-trans: #F95C4A41;
    --prim-trans2: #F95C4A7a;
    --prim-mid: #D2355B;
    --prim-dark: #A60A6D;
	--light-grey: #e3e3e3;
    --very-light-grey: #f3f3f3;
    --bg-color: #fafbfd;
	--blue: #84b9f0;
	--orange: orange;
	--really-light-grey: #f8f8f8;
}

.footer-container {
    position: relative;
    width: 100%;
    left: 0;
    bottom: 0;
}

*, :focus {
    outline: none !important;
    outline-offset: 0px;
}

*, :after, :before {
    box-sizing: border-box;
}

body {
    font-family: 'Lexend', sans-serif;
    box-sizing: border-box;
    font-size: 0.875em;
    background-color: var(--bg-color);
    min-height: 100vh;
}

main {
    text-align: center;
    vertical-align: center;
    line-height: 100vh
}

a {
    text-decoration: none !important;
    color: var(--dark-grey) !important;

    &.white {
        color: white !important;
        font-weight: bold;
    }
}

.hide {
    display:none;
}
  
.clickable {
    cursor: pointer;
    transition: all 150ms;

    &:hover {
        transform: translateY(-3px);
    
        &.static {
            transform: none;
        }
    }
}
  
.g-container {
    width: 100%;
    max-width: 1150px;
    margin: auto;
// padding: 0 14px;
}
  
.g-container.no-pad {
    max-width: 80%;
    margin: auto;
}
  
.center {
    text-align: center;
}
  
.g-card {
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    padding: 20px 30px;
    border-radius: 10px;
    transition: .5s;
  
    &.pad {
        margin: 14px 0;
    }
  
    &.button-card:hover {
        transform: translateY(-2px);
        box-shadow: 10px 10px 20px 0 rgb(0 0 0 / 10%);
    }
}
  
.g-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-items: stretch;
  
    // * > :first-child { 
    // 	margin-top: 0 !important; 
    // 	margin-left: 0 !important; 
    // }
    
    // * > :last-child { 
    // 	margin-bottom: 0 !important; 
    // 	margin-right: 0 !important; 
    // }
  
    .g-col {
        width: 100%;
    }
    
    .g-half-col {
        width: 48%;
    }
  
    .g-qtr-col {
        width: 24%;
    }
  
    .g-third-col {
        width: 33%;
    }
  
    .g-two-third-col {
        width: 66%;
    }
  
    .g-three-qtr-col {
        width: 74%;
    }
  
}

/* CSS */
input[type="file"]::file-selector-button,
.g-button {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: var(--dark-grey);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-evenly;
  line-height: 1.25;
  margin: 0 5px;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &.padded {
    margin: 10px;
  }

  &.small-pad {
    padding: 4px 7px;
    line-height: 1px;
    min-height: 0px;
  }


  
  &.minor {
      background-color: transparent;
      color: white;
      border: 2px solid rgba(255, 255, 255, .5);

      &:hover {
          background-color: rgba(255, 255, 255, 1);
          color: var(--prim-color);
      }
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: var(--prim-color);
  }
}

.g-button.big {
    // padding: 4px 7px;
    font-size: 20px;
    line-height: 24px;
    min-height: 0px;
}

.g-button.small-pad {
    padding: 4px 7px;
    line-height: 24px;
    min-height: 0px;
}

.g-button.primary {
  background-image: linear-gradient(to right, var(--prim) 0%, var(--prim-dark) 51%, var(--prim) 100%);
  background-size: 200% auto;
  color: white;
  transition: 0.5s;

  &:hover {
    background-position: right center;
    color: white;
  }
}

// input[type="file"]::file-selector-button {
//   width: 200px;
//   overflow: hidden;
//   margin: 10px 0;
// }


// #iconUpload {
//   color:transparent;
//   width: 200px;
//   overflow: hidden;
// }

.g-button:hover {
  transform: translateY(-3px);

  &.static {
    transform: none;
  }
}

.g-button:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.g-button.disabled {
  color:#999999 !important;
  cursor: auto;
  background-color: #e7e7e7;

  &:hover {
    transform: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  }

  &:active {
    transform: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    touch-action: none;
    
  }
}

/*
*  SCROLLBAR
*/

/* width */
::-webkit-scrollbar {
    width: 4px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #e4e4e4; 
    border-radius: 3px;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb; 
}

/** 
* FORM
*/

label {
  display: flex;
  padding-bottom: 14px;
}

.field {
    padding-bottom: 24px;
}

.field input,
.field textarea,
.field select {
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    color: slategray; 
    font-family: 'Lexend', sans-serif;
    border-radius: 4px;
    outline-color: rgb(84, 105, 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    &.small {
        padding: 4px 8px;
        min-height: 30px;
        line-height: 18px;
    }
}

input[type="submit"] {
    background-color: rgb(84, 105, 212);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, 
                rgb(84, 105, 212) 0px 0px 0px 1px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(0, 0, 0, 0) 0px 0px 0px 0px, 
                rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}
.field-checkbox input {
    width: 20px;
    height: 15px;
    margin-right: 5px; 
    box-shadow: unset;
    min-height: unset;
}
.field-checkbox label {
    display: flex;
    align-items: center;
    margin: 0;
}


/*
* Spacers
*/

.g-space-10 {
    height: 10px;
    background-color:transparent;
}

.g-space-20 {
    height: 20px;
    background-color:transparent;
}

.g-space-30 {
    height: 30px;
}

.g-space-40 {
    height: 40px;
}

.g-space-60 {
    height: 60px;
}


@media (max-width: 920px) {
    .g-grid {
        display: block;

        .g-col,
        .g-half-col,
        .g-qtr-col,
        .g-third-col, 
        .g-two-third-col,
        .g-three-qtr-col {
            width: 100%;
        }
    }
}